import React from 'react';
import { IntegrityForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const TimeRuleDataFormComponent = ({ integrity, updateValue }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (integrity.value !== values.value) updateValue(values);
    // eslint-disable-next-line
  }, [values]);
  return (
    <Grid item xs={6}>
      <Field name={IntegrityForm.VALUE}>
        {({ form, ...formik }) => (
          <DateTimePickerFormik
            style={{ paddingTop: '3px' }}
            autoOk
            placeholder="yyyy/mm/dd hh:mm"
            form={form}
            {...formik}
          />
        )}
      </Field>
    </Grid>
  );
};

export default TimeRuleDataFormComponent;
