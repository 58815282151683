import { KeyboardDatePicker } from '@material-ui/pickers';

import { Format } from 'app/app.constants';

const DatePickerField = ({
  input,
  format,
  DatePickerProps,
  inputVariant,
  margin,
  meta,
  hideFormatHelperText = false,
}) => {
  const { onChange, value } = input;

  const helperText = meta.error
    ? meta.error
    : hideFormatHelperText
    ? ''
    : format;

  return (
    <KeyboardDatePicker
      autoOk
      fullWidth
      value={value}
      format={format}
      onChange={onChange}
      helperText={helperText}
      margin={margin}
      inputVariant={inputVariant}
      FormHelperTextProps={{
        error: !!meta.error,
      }}
      {...DatePickerProps}
    />
  );
};

DatePickerField.defaultProps = {
  margin: 'normal',
  format: Format.date,
  inputVariant: 'standard',
};

export default DatePickerField;
