import { compose } from 'redux';
import { connect } from 'react-redux';
import { useState, memo } from 'react';
import { Tab, Tabs, Grid } from '@material-ui/core';

import useWellbore from 'features/wells/hooks/useWellbore';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import IntegrityRiskMatrix from 'features/wells/integrity/IntegrityRiskMatrix';
import {
  INTEGRITY_MANAGEMENT_ACTIONS,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  getIntegrityManagementIntegritiesFromState,
  getIntegrityManagementIntegritiesFromStateByRiskPrinciple,
} from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import { useEffect } from 'react';
import { loadIntegrityManagementIntegritiesForWell } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import WellIntegrityRiskTrendChart from './WellIntegrityRiskTrendChart';

const WellIntegrityGraphs = ({
  wellboreId,
  integrities,
  filteredIntegritiesLikelihood,
  filteredIntegritiesConsequence,
  dispatchLoadIntegrityManagementForWell,
}) => {
  const INTEGRITY_GRAPHS_TABS = {
    RISK_TREND_CHART: 'RISK_TREND_CHART',
    LATEST_RISK_SCORE_LIKELIHOOD: 'LATEST_RISK_SCORE_LIKELIHOOD',
    LATEST_RISK_SCORE_CONSEQUENCE: 'LATEST_RISK_SCORE_CONSEQUENCE',
  };

  const { wellbore } = useWellbore(wellboreId);
  const [finalScore, setFinalScore] = useState(0);
  const [externalWellboreId, setExternalWellboreId] = useState(null);

  const [activeTab, setActiveTab] = useState(
    INTEGRITY_GRAPHS_TABS.RISK_TREND_CHART,
  );

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  // for loading integrities
  useEffect(() => {
    if (wellbore?.get('externalWellboreExternalId')) {
      dispatchLoadIntegrityManagementForWell(
        wellbore.get('externalWellboreExternalId'),
      );
      setExternalWellboreId(wellbore.get('externalWellboreExternalId'));
    }
  }, [dispatchLoadIntegrityManagementForWell, wellbore]);

  // for calculating score for matrix
  useEffect(() => {
    let score = 1;
    integrities &&
      integrities.forEach((integrity) => {
        score *= integrity.riskPrincipleScore
          ? integrity.riskPrincipleScore.score
          : 1;
      });
    setFinalScore(score.toFixed(3));
  }, [integrities]);

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Tabs value={activeTab} onChange={handleTabs}>
          <Tab
            label="Risk trend"
            value={INTEGRITY_GRAPHS_TABS.RISK_TREND_CHART}
          />
          <Tab
            label="Latest risk score (Likelihood)"
            value={INTEGRITY_GRAPHS_TABS.LATEST_RISK_SCORE_LIKELIHOOD}
          />
          <Tab
            label="Latest risk score (Conseqence)"
            value={INTEGRITY_GRAPHS_TABS.LATEST_RISK_SCORE_CONSEQUENCE}
          />
        </Tabs>
        <Grid container>
          {activeTab === INTEGRITY_GRAPHS_TABS.RISK_TREND_CHART && (
            <Grid item xs={12}>
              <WellIntegrityRiskTrendChart
                externalWellboreId={externalWellboreId}
              />
            </Grid>
          )}
          {activeTab === INTEGRITY_GRAPHS_TABS.LATEST_RISK_SCORE_LIKELIHOOD && (
            <Grid item xs={12}>
              <IntegrityRiskMatrix
                finalScore={finalScore}
                filteredIntegrities={filteredIntegritiesLikelihood}
                title={RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE}
              />
            </Grid>
          )}
          {activeTab ===
            INTEGRITY_GRAPHS_TABS.LATEST_RISK_SCORE_CONSEQUENCE && (
            <Grid item xs={12}>
              <IntegrityRiskMatrix
                finalScore={finalScore}
                filteredIntegrities={filteredIntegritiesConsequence}
                title={RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(
  connect(
    (state) => {
      return {
        integrities: getIntegrityManagementIntegritiesFromState(state),
        filteredIntegritiesLikelihood:
          getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
            state,
            RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
          ),
        filteredIntegritiesConsequence:
          getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
            state,
            RISK_PRINCIPLE_TYPES.WELL_CONSEQUENCE,
          ),
        dataState: getSummarizedDataStateFromState(
          state,
          INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
        ),
      };
    },
    {
      dispatchLoadIntegrityManagementForWell:
        loadIntegrityManagementIntegritiesForWell,
    },
  ),
  memo,
)(WellIntegrityGraphs);
