import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import RadioGroupFieldFormik from 'app/components/form/formik/RadioGroupFieldFormik';
import {
  BOOLEAN_DATA_VALUES_ENUM,
  IntegrityForm,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const BooleanRuleDataFormComponent = ({ updateValue, integrity }) => {
  const { values } = useFormikContext();

  const options = [BOOLEAN_DATA_VALUES_ENUM.YES, BOOLEAN_DATA_VALUES_ENUM.NO];

  useEffect(() => {
    if (integrity.value !== values.value) updateValue(values);
    // eslint-disable-next-line
  }, [values]);
  return (
    <Grid item xs={6}>
      <Field name={IntegrityForm.VALUE}>
        {({ form, ...formik }) => {
          formik.field.onChange = (e) => {
            form.setFieldValue(IntegrityForm.VALUE, Number(e.target.value));
          };
          return (
            <RadioGroupFieldFormik
              options={options}
              form={form}
              row={false}
              {...formik}
            />
          );
        }}
      </Field>
    </Grid>
  );
};

export default BooleanRuleDataFormComponent;
