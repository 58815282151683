import { useRef, useEffect } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
// import { WellBoreNode } from '@int/geotoolkit/schematics/scene/WellBoreNode';
import { WellBoreData } from '@int/geotoolkit/schematics/data/WellBoreData';
import { CompositeSchematicsWidget } from '@int/geotoolkit/schematics/widgets/CompositeSchematicsWidget';
// import wellBoreData from './helpers/wellboreData.json';
import wellBoreNodeLatestData from './helpers/wellBoreNodeLatestData.json';
import { FillStyle } from '@int/geotoolkit/attributes/FillStyle';
// import { WellBoreWithLabels } from '@int/geotoolkit/schematics/scene/WellBoreWithLabels';
// import { from } from '@int/geotoolkit/selection/from';
import { ViewMode } from '@int/geotoolkit/schematics/scene/WellBoreNode';

import './wellBoreStyleLatest.css';

// const createWellBoreData = () => {
//   return new WellBoreData(wellBoreData);
// };

const options = {
  data: new WellBoreData(wellBoreNodeLatestData),
  wellborenode: {
    viewmode: ViewMode.Compressed,
    trackwidth: 200,
  },
  legend: {
    offset: { x: -10, y: 10 },
    width: 250,
    height: 300,
    visible: true,
  },
  gap: {
    horizontal: {
      left: 10,
    },
  },
  // north: { title: { text: 'Schematic 2D Well Bore' } },
  // gap: {
  //   top: {
  //     visible: false,
  //     resizable: false,
  //     size: '0',
  //   },
  //   left: {
  //     visible: true,
  //     resizable: false,
  //     size: '30px',
  //   },
  //   right: {
  //     visible: true,
  //     resizable: false,
  //     size: '30px',
  //   },
  //   bottom: {
  //     visible: false,
  //     resizable: false,
  //     size: '0',
  //   },
  // },
  // annotationssizes: {
  //   south: 0,
  //   west: 0
  // },
  // data: {
  //   elements: createWellBoreData()
  // },
  // tools: {
  //   rubberbandzoom: {
  //     mode: 'Free',
  //     linestyle: 'red',
  //   },
  // },
  // east: [],
  // west: [],
  // center: {axis:{visible:false}, transformations: {dx: 0,
  //   dy: 0,
  //   xx: 0,
  //   xy: 0,
  //   yx: 0,
  //   yy: 0,}},
  // none: [],
  // northeast: [],
  // northwest: [],
  // border: null,
  // annotationitemswrap:false,
  // animationstyle: null,
  // clipping: false,
  // overlays: []
};

const WellBoreSchematic = () => {
  const canvasRef = useRef();
  const schematicWidgetRef = useRef();

  const schematicWidget = new CompositeSchematicsWidget(options);
  schematicWidgetRef.current = schematicWidget;

  useEffect(() => {
    if (canvasRef.current) {
      // const wellBoreNode = new WellBoreNode({
      //   data: new WellBoreData(wellBoreNodeLatestData),
      // });

      new Plot({
        canvaselement: canvasRef.current,
        root: schematicWidgetRef.current,
      });
      // const wellBoreWithLabels = from(schematicWidget.getModel())
      //   .where((node) => {
      //     return node instanceof WellBoreWithLabels;
      //   })
      //   .selectFirst();
      // wellBoreWithLabels.setProperties({
      //     'labeling': {
      //         'labelshape': new SymbolLabelShape({'painter': CirclePainter})
      //     }
      // });
      schematicWidgetRef.current.setFillStyle(new FillStyle('#303030'));
    }
  }, []);
  return (
    <main>
      <div style={{ alignItems: 'flex-start', width: '900px' }}>
        <button
          type="button"
          onClick={() => {
            schematicWidgetRef.current.zoomIn();
          }}
        >
          Zoom
        </button>
        <button
          type="button"
          onClick={() => {
            schematicWidgetRef.current.fitToBounds();
          }}
        >
          Zoom to fit
        </button>
      </div>
      <canvas ref={canvasRef} width="900px" height="800px" />
    </main>
  );
};

export default WellBoreSchematic;
