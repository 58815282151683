export const WELLS_ACTIONS = {
  GET_FIELDS: 'GET_FIELDS',
  GET_WELLBORE: 'GET_WELLBORE',
  WELLBORE_INT_FETCH_STARTED: 'WELLBORE_INT_FETCH_STARTED',
  WELLBORE_INT_FETCH_SUCCEEDED: 'WELLBORE_INT_FETCH_SUCCEEDED',
  WELLBORE_INT_FETCH_FAILED: 'WELLBORE_INT_FETCH_FAILED',
  RECEIVE_FIELDS: 'RECEIVE_FIELDS',
  WELLS_PAGE_LOADED: 'WELLS_PAGE_LOADED',
  UPDATE_WELL_FILTER: 'UPDATE_WELL_FILTER',
  RECEIVE_WELLBORE_DETAIL: 'RECEIVE_WELLBORE_DETAIL',
  UPDATE_WELLBORE: 'UPDATE_WELLBORE',
  RECEIVE_WELLBORE: 'RECEIVE_WELLBORE',
  RECEIVE_WELLBORES_PAGE: 'RECEIVE_WELLBORES_PAGE',
  CLEAR_WELLBORES: 'CLEAR_WELLBORES',
  WELLBORE_CONTAINER_LOADED: 'WELLBORE_CONTAINER_LOADED',
  WELLBORE_CONTAINER_UNLOADED: 'WELLBORE_CONTAINER_UNLOADED',
  RECEIVE_WELLBORE_TRAJECTORY: 'RECEIVE_WELLBORE_TRAJECTORY',
  REQUEST_WELLBORE_TRAJECTORY: 'REQUEST_WELLBORE_TRAJECTORY',
  UPDATE_WELLBORE_DETAIL: 'UPDATE_WELL_DETAIL',
  CREATE_WELLBORE_DETAIL: 'CREATE_WELL_DETAIL',
  ADD_HOSTILE_FLUIDS: 'ADD_HOSTILE_FLUIDS',
  REMOVE_HOSTILE_FLUIDS: 'REMOVE_HOSTILE_FLUIDS',
  ADD_WELLBORE_DETAIL_SERVICES: 'ADD_WELLBORE_DETAIL_SERVICES',
  DELETE_WELLBORE_DETAIL_SERVICE: 'DELETE_WELLBORE_DETAIL_SERVICE',

  // FLUID ACTIONS
  INITIATE_GET_WELLBORE_FLUIDS: 'INITIATE_GET_WELLBORE_FLUIDS',
  GET_WELLBORE_FLUIDS: 'GET_WELLBORE_FLUIDS',
  INITIATE_DELETE_WELLBORE_FLUID: 'INITIATE_DELETE_WELLBORE_FLUID',
  DELETE_WELLBORE_FLUID: 'DELETE_WELLBORE_FLUID',
  INITIATE_UPDATE_WELLBORE_FLUID: 'INITIATE_UPDATE_WELLBORE_FLUID',
  UPDATE_WELLBORE_FLUID: 'UPDATE_WELLBORE_FLUID',
  INITIATE_CREATE_WELLBORE_FLUID: 'INITIATE_CREATE_WELLBORE_FLUID',
  CREATE_WELLBORE_FLUID: 'CREATE_WELLBORE_FLUID',
};

export const WellStatus = {
  UNKNOWN: 0,
  CLOSED: 1,
  DRILLING: 2,
  JUNKED: 3,
  ONLINE_OPERATIONAL: 4,
  P_AND_A: 5,
  PLUGGED: 6,
  PREDRILLED: 7,
  SUSPENDED: 8,
  WILL_NEVER_BE_DRILLED: 9,
};

export const ADD_WELLBORE_DETAILS_HOSTILE_FLUIDS_MODAL_ID =
  'ADD_WELLBORE_DETAILS_HOSTILE_FLUIDS_MODAL_ID';

export const ADD_WELLBORE_DETAIL_SERVICES_MODAL_ID =
  'ADD_WELLBORE_DETAIL_SERVICES_MODAL_ID';
