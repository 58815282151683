import { Map, OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { WELLS_ACTIONS as ACTIONS } from 'features/wells/wells.constants';

const initialState = Map({
  pageParams: Map({
    currentPage: 1,
    pageSize: 50,
  }),
  pageResults: EMPTY_MAP,
  wellboreIntData: {
    data: null,
    isFetching: false,
    error: null,
  },
});

const wellboreReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_WELLBORE: {
      if (error) return state;

      return state.setIn(
        ['pageResults', payload.wellboreId.toString()],
        mappers.Wellbore.from(payload),
      );
    }

    case ACTIONS.RECEIVE_WELLBORES_PAGE: {
      if (error) return state;

      const { results: wellbores, ...pageParams } = payload;

      return state
        .set('pageParams', mappers.PageParams.from(pageParams))
        .set(
          'pageResults',
          OrderedMap(
            wellbores.map((wellbore) => [
              wellbore.wellboreId.toString(),
              mappers.Wellbore.from(wellbore),
            ]),
          ),
        );
    }

    case ACTIONS.UPDATE_WELLBORE: {
      if (error) return state;

      const { wellboreId } = action;

      return state.setIn(['pageResults', wellboreId.toString()], payload);
    }

    case ACTIONS.CLEAR_WELLBORES: {
      return initialState;
    }

    case ACTIONS.WELLBORE_INT_FETCH_STARTED: {
      return state.set('wellboreIntData', {
        data: null,
        isFetching: true,
        error: null,
      });
    }

    case ACTIONS.WELLBORE_INT_FETCH_SUCCEEDED: {
      return state.set('wellboreIntData', {
        data: payload,
        isFetching: false,
        error: null,
      });
    }

    case ACTIONS.WELLBORE_INT_FETCH_FAILED: {
      return state.set('wellboreIntData', {
        data: null,
        isFetching: false,
        error: payload,
      });
    }

    default:
      return state;
  }
};

export default wellboreReducer;
