import { createSelector } from 'utils/app.util';
import { INTEGRITY_TYPE_ENUM } from './integrityManagement.constants';
import { EMPTY_LIST } from 'app/app.constants';

export const getIntegrityManagementIntegritiesFromState = createSelector(
  (state) => state.getIn(['entity', 'integrityManagement', 'integrities']),
  (integrities) => integrities,
);

export const getIntegrityManagementIntegritiesFromStateByRiskPrinciple =
  createSelector(
    [
      getIntegrityManagementIntegritiesFromState,
      (_, riskPrincipleType) => riskPrincipleType,
    ],
    (integrities, riskPrincipleType) =>
      (integrities || EMPTY_LIST).filter(
        (item) =>
          item.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE &&
          item.title === riskPrincipleType,
      ),
  );

export const getIntegrityManagementWellIntegrityAttachmentsFromState =
  createSelector(
    (state) =>
      state.getIn(['entity', 'integrityManagement', 'integrityAttachments']),
    (attachments) => attachments,
  );

export const getIntegrityManagementAvailableFilesState = createSelector(
  (state) => state.getIn(['entity', 'integrityManagement', 'availableFiles']),
  (availableFiles) => availableFiles,
);

export const getIntegrityManagementWellRiskScoresTrendState = createSelector(
  (state, externalWellboreId) =>
    state.getIn([
      'entity',
      'integrityManagement',
      `riskScoreTrend,${externalWellboreId}`,
    ]),
  (riskTrendScores) => riskTrendScores,
);
