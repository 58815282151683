import moment from 'moment';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import {
  IntegrityForm,
  INTEGRITY_TYPE_ENUM,
  INTEGRITY_MANAGEMENT_ACTIONS,
  UPLOAD_ATTACHMENT_FORM,
  ATTACHMENTS_DATE_FORMAT,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementService from 'features/settings/integrityManagement/helpers/integrityManagement.service';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

export const loadIntegrityManagementIntegrities = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
    payload,
  });
  return payload;
};

export const deleteIntegrityManagementIntegrity =
  (integrityId) => (dispatch) => {
    dispatch({
      integrityId,
      type: INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_INTEGRITY,
      confirmationDialog: {
        title: 'Delete integrity',
        confirmButtonText: 'Delete',
        description: 'Are you sure you want to delete this integrity?',
      },
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Deleting integrity',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Integrity successfully Deleted!',
        [NOTIFICATION_VARIANTS.ERROR]:
          'Cannot delete category with category / rule',
      },
      payload: () => {
        return integrityManagementService
          .deleteIntegrityManagementIntegrity(integrityId)
          .then(() => {
            dispatch(loadIntegrityManagementIntegrities());
          });
      },
    });
  };

export const addNewIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const integrityType =
      integrity[IntegrityForm.INTEGRITY_TYPE] === INTEGRITY_TYPE_ENUM.CATEGORY
        ? 'Category'
        : 'Rule';

    const payload = integrityManagementService
      .addNewIntegrityManagementIntegrity(integrity)
      .then(() => dispatch(loadIntegrityManagementIntegrities()));

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_INTEGRITY,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: `Error creating ${integrityType}.`,
        [NOTIFICATION_VARIANTS.INFO]: `Adding new ${integrityType}...`,
        [NOTIFICATION_VARIANTS.SUCCESS]: `New ${integrityType} successfully added!`,
      },
      payload,
    });
  };

export const updateIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const integrityType =
      integrity[IntegrityForm.INTEGRITY_TYPE] === INTEGRITY_TYPE_ENUM.CATEGORY
        ? 'Category'
        : 'Rule';

    const payload = integrityManagementService
      .updateIntegrityManagementIntegrity(integrity)
      .then(() => dispatch(loadIntegrityManagementIntegrities()));

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.UPDATE_INTEGRITY_MANAGEMENT_INTEGRITY,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: `Error updating ${integrityType}.`,
        [NOTIFICATION_VARIANTS.INFO]: `Updating ${integrityType}...`,
        [NOTIFICATION_VARIANTS.SUCCESS]: `${integrityType} successfully updated!`,
      },
      payload,
    });
  };

export const loadIntegrityManagementIntegritiesForWell =
  (externalWellboreId) => (dispatch) => {
    const payload =
      integrityManagementService.getIntegrityManagementIntegritiesForWell(
        externalWellboreId,
      );
    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
      payload,
    });
    return payload;
  };

export const addNewIntegrityManagementWellData =
  (wellboreId, formValues, toggleModal) => (dispatch) => {
    const { ...integrity } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityWellData(wellboreId, integrity)
      .then(() => {
        return dispatch(
          loadIntegrityManagementIntegritiesForWell(wellboreId),
        ).then(() => {
          toggleModal();
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE_DATA,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error updating rule data.',
        [NOTIFICATION_VARIANTS.INFO]: 'Updating rule data.',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule data successfully added!',
      },
      payload,
    });
  };

export const loadIntegrityManagementWellIntegrityAttachments =
  (externalWellboreId, integrityId) => (dispatch) => {
    const payload =
      integrityManagementService.getIntegrityManagementWellIntegrityAttachments(
        externalWellboreId,
        integrityId,
      );

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_WELL_INTEGRITY_ATTACHMENTS,
      payload,
    });

    return payload;
  };

export const loadIntegrityManagementAvailableFiles =
  (externalWellboreId) => (dispatch) => {
    const payload =
      integrityManagementService.getIntegrityManagementAvailableFiles(
        externalWellboreId,
      );

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_AVAILABLE_FILES,
      payload,
    });

    return payload;
  };

export const updateIntegrityManagementWellAttachments =
  (externalWellboreId, integrityId, attachments, toggleModal) => (dispatch) => {
    const payload = integrityManagementService
      .modifyIntegrityManagementWellAttachments(
        externalWellboreId,
        integrityId,
        attachments,
      )
      .then(() => {
        dispatch(
          loadIntegrityManagementWellIntegrityAttachments(
            externalWellboreId,
            integrityId,
          ),
        );
      })
      .then(() => {
        dispatch(loadIntegrityManagementAvailableFiles(externalWellboreId));
      })
      .then(() => {
        toggleModal();
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.UPDATE_INTEGRITY_MANAGEMENT_WELL_INTEGRITY_ATTACHMENTS,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error updating attachments.',
        [NOTIFICATION_VARIANTS.INFO]: 'Updating attachments...',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Attachments successfully updated!',
      },
      payload,
    });
  };

export const uploadAndUpdateIntegrityManagementWellAttachments =
  (externalWellboreId, integrityId, attachments, files, toggleModal) =>
  (dispatch) => {
    const fileContents = files.map((file) => file.content);

    var details = files.map((file) => ({
      name: file.name,
      reportingDate: moment(file.reportingDate, ATTACHMENTS_DATE_FORMAT).format(
        'YYYY-MM-DDTHH:mm:ss',
      ),
    }));

    var attachmentsForBackend = attachments?.map(
      integrityManagementMappers.attachments.mapAttachment,
    );

    // Initialize the form with files and metadata
    const meta = {
      [UPLOAD_ATTACHMENT_FORM.FILES]: fileContents,
      [UPLOAD_ATTACHMENT_FORM.ATTACHMENTS]: JSON.stringify(
        attachmentsForBackend,
      ),
      [UPLOAD_ATTACHMENT_FORM.FILE_DETAILS]: JSON.stringify(details),
    };

    const payload = integrityManagementService
      .uploadAndUpdateIntegrityManagementWellAttachments(
        externalWellboreId,
        integrityId,
        fileContents,
        meta,
      )
      .then(() => {
        dispatch(
          loadIntegrityManagementWellIntegrityAttachments(
            externalWellboreId,
            integrityId,
          ),
        );
      })
      .then(() => {
        dispatch(loadIntegrityManagementAvailableFiles(externalWellboreId));
      })
      .then(() => {
        toggleModal();
      });

    dispatch({
      files,
      type: INTEGRITY_MANAGEMENT_ACTIONS.UPDATE_INTEGRITY_MANAGEMENT_WELL_INTEGRITY_ATTACHMENTS,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]:
          'Error uploading and updating attachments.',
        [NOTIFICATION_VARIANTS.INFO]: 'Uploading and updating attachments...',
        [NOTIFICATION_VARIANTS.SUCCESS]: `The ${
          files.length > 1 ? 'Attachments' : 'Attachment'
        } ${
          files.length > 1 ? 'were' : 'was'
        } successfully uploaded and updated.`,
      },
      payload,
    });
  };

export const loadIntegrityManagementRiskTrendScoresForWell =
  (externalWellboreId) => async (dispatch) => {
    if (!externalWellboreId) {
      return;
    }

    const payload =
      await integrityManagementService.getIntegrityManagementRiskScoreTrendForWell(
        externalWellboreId,
      );

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_INTEGRITY_MANAGEMENT_RISK_SCORE_TREND_FOR_WELL,
      payload: {
        externalWellboreId,
        scores: payload,
      },
    });
  };
