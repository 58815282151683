import { useEffect, useRef } from 'react';
import { CompositeSchematicsWidget } from '@int/geotoolkit/schematics/widgets/CompositeSchematicsWidget';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { WellBoreData } from '@int/geotoolkit/schematics/data/WellBoreData';

import './wellBoreStyle.css';

function createWellBoreData(schemeData) {
  return new WellBoreData(schemeData);
}

const options = {
  north: { title: { text: 'Schematic 2D Well Bore' } },
  gap: {
    top: {
      visible: false,
      resizable: false,
      size: '0',
    },
    left: {
      visible: true,
      resizable: false,
      size: '30px',
    },
    right: {
      visible: true,
      resizable: false,
      size: '30px',
    },
    bottom: {
      visible: false,
      resizable: false,
      size: '0',
    },
  },
  annotationssizes: {
    south: 0,
  },
  data: { elements: {} },
};

const WellBoreSchematic = ({ data }) => {
  const canvasRef = useRef();

  const hasData = data !== null && data.lentgh !== 0;

  useEffect(() => {
    if (canvasRef.current && hasData) {
      options.data.elements = createWellBoreData(data);
      const schematicWidget = new CompositeSchematicsWidget(options);
      new Plot({
        canvaselement: canvasRef.current,
        root: schematicWidget,
      });
    }
  }, [data, hasData]);

  //will be changed to display component for this use case
  if (!hasData) {
    return 'No data to display, please create scheme.';
  }

  return <canvas ref={canvasRef} style={{ width: '550px', height: '800px' }} />;
};

export default WellBoreSchematic;
