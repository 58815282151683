import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import {
  Box,
  Tab,
  Tabs,
  Grid,
  List,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import withStyles from '@material-ui/styles/withStyles';
import { EMPTY_LIST, MAX_UPLOAD_FILE_SIZE, Format } from 'app/app.constants';
import TextField from 'app/components/form/TextFieldV2';
import {
  ATTACHMENTS_DATE_FORMAT,
  INTEGRITY_ATTACHMENT_FILES_TABS,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import DatePickerField from 'app/components/form/DatePickerField';

const UploadIntegrityAttachmentDrawerContent = ({
  classes,
  toggleDrawer,
  projectFiles,
  wellboreFiles,
  handleUpdateAttachments,
  isSubmitting,
  setIsSubmitting,
}) => {
  const [activeTab, setActiveTab] = useState(
    INTEGRITY_ATTACHMENT_FILES_TABS.WELL,
  );

  const [newWellboreFiles, setNewWellboreFiles] = useState(EMPTY_LIST);
  const [newProjectFiles, setNewProjectFiles] = useState(EMPTY_LIST);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setNewWellboreFiles(wellboreFiles);
  }, [wellboreFiles]);

  useEffect(() => {
    setNewProjectFiles(projectFiles);
  }, [projectFiles]);

  const handleWellboreFilesCheckboxToggle = (externalId) => {
    setNewWellboreFiles((prev) =>
      prev.map((file) =>
        file.externalId === externalId
          ? { ...file, isChecked: !file.isChecked }
          : file,
      ),
    );
  };

  const handleProjectFilesCheckboxToggle = (projectId, externalId) => {
    setNewProjectFiles((prev) =>
      prev.updateIn([projectId], (files) =>
        files.map((file) =>
          file.externalId === externalId
            ? { ...file, isChecked: !file.isChecked }
            : file,
        ),
      ),
    );
  };

  // for running update of the attachments
  const submitUpdatedAttachments = () => {
    setIsSubmitting(true);
    const attachments = [];

    // get attachments from project
    if (!newProjectFiles.isEmpty()) {
      var projectAttachments = newProjectFiles
        .valueSeq()
        .flatMap((group) => group.filter((item) => item.isChecked))
        .toJS();
      if (projectAttachments && projectAttachments.length > 0) {
        attachments.push(...projectAttachments);
      }
    }

    // get attachments from well
    if (!newWellboreFiles.isEmpty()) {
      var wellboreAttachments = newWellboreFiles
        .filter((file) => file.isChecked === true)
        .toJS();
      if (wellboreAttachments && wellboreAttachments.length > 0) {
        attachments.push(...wellboreAttachments);
      }
    }

    handleUpdateAttachments(attachments, uploadedFiles);
  };

  // for tabz on file selection
  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  // for toggle on the list items for projects
  const [expand, setExpand] = useState({});

  const handleToggle = (projectId) => {
    setExpand((prev) => ({ ...prev, [projectId]: !prev[projectId] }));
  };

  // for dropzone upload
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          id: uuidv4(),
          name: file.name,
          reportingDate: moment(), // default
          content: file,
        })),
      ]);
    },
    disabled: isSubmitting,
    noClick: false,
    noKeyboard: true,
    maxSize: MAX_UPLOAD_FILE_SIZE,
  });

  const handleRemoveFile = (fileId) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId),
    );
  };

  const [editRowId, setEditRowId] = useState(null); // which row (by ID) is currently in edit mode
  const [editingValues, setEditingValues] = useState({
    name: '',
    reportingDate: '',
  });

  // -- Inline edit: Start editing a particular row --
  const handleStartEdit = (file) => {
    setEditRowId(file.id);
    setEditingValues({
      name: file.name,
      reportingDate: moment(file.reportingDate),
    });
  };

  const handleEditChange = (field, value) => {
    setEditingValues((prev) => ({ ...prev, [field]: value }));

    if (field === 'reportingDate') {
      const newDate = moment(value);
      if (newDate.isValid()) {
        setEditingErrors((prev) => ({ ...prev, reportingDate: '' }));
      }
    }
  };

  const [editingErrors, setEditingErrors] = useState({
    name: '',
    reportingDate: '',
  });

  const handleSaveRow = async (fileId) => {
    try {
      // Validate the current editingValues using our schema
      await fileSchema.validate(editingValues, { abortEarly: false });

      // If no errors, update the file in uploadedFiles
      setUploadedFiles((prevFiles) =>
        prevFiles.map((file) => {
          if (file.id === fileId) {
            return {
              ...file,
              name: editingValues.name,
              reportingDate: editingValues.reportingDate,
            };
          }
          return file;
        }),
      );

      // Exit edit mode
      setEditRowId(null);
    } catch (err) {
      // If validation fails, Yup throws an error with `inner` containing each error
      if (err.inner) {
        const newErrors = { name: '', reportingDate: '' };
        err.inner.forEach((validationError) => {
          if (validationError.path === 'name') {
            newErrors.name = validationError.message;
          }
          if (validationError.path === 'reportingDate') {
            newErrors.reportingDate = validationError.message;
          }
        });
        setEditingErrors(newErrors);
      }
    }
  };

  const fileSchema = yup.object().shape({
    name: yup.string().required('Document name is required'),
    reportingDate: yup
      .date()
      .required('Reported date is required')
      .typeError('Must be a valid date'),
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            {...getRootProps()}
            className={`${classes.dropzone} ${
              isDragActive ? classes.dragActive : ''
            }`}
          >
            <input {...getInputProps()} />
            <Typography variant="body1" color="text.secondary" gutterBottom>
              Drag and drop the file here or
            </Typography>
            <Button
              variant="text"
              startIcon={<CloudUploadIcon />}
              sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              Upload File
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {uploadedFiles.length ? 'Document' : null}
                  </TableCell>
                  <TableCell>
                    {uploadedFiles.length ? 'Reported Date' : null}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadedFiles.map((file) => {
                  const isEditing = editRowId === file.id;
                  return (
                    <TableRow key={file.id}>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            size="small"
                            value={editingValues.name}
                            onChange={(e) =>
                              handleEditChange('name', e.target.value)
                            }
                            error={!!editingErrors.name}
                            helperText={editingErrors.name}
                          />
                        ) : (
                          file.name
                        )}
                      </TableCell>
                      <TableCell>
                        {isEditing ? (
                          <DatePickerField
                            input={{
                              value: editingValues.reportingDate,
                              onChange: (date) => {
                                const newDate = moment(date);
                                handleEditChange('reportingDate', newDate);
                                
                                if (newDate.isValid()) {
                                  setEditingErrors((prev) => ({
                                    ...prev,
                                    reportingDate: '',
                                  }));
                                }
                              },
                            }}
                            meta={{
                              error: editingErrors.reportingDate,
                            }}
                            format={Format.date_dayfirst}
                            hideFormatHelperText={true}
                          />
                        ) : file.reportingDate ? (
                          moment(file.reportingDate).format(
                            Format.date_dayfirst,
                          )
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          {isEditing ? (
                            <IconButton
                              onClick={() => handleSaveRow(file.id)}
                              color="primary"
                            >
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleStartEdit(file)}>
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={() => handleRemoveFile(file.id)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            onChange={handleTabs}
            className={classes.tabsContainer}
            indicatorColor="primary"
          >
            <Tab
              label="FROM WELL"
              value={INTEGRITY_ATTACHMENT_FILES_TABS.WELL}
              className={classes.tab}
            />
            <Tab
              label="FROM PROJECT"
              value={INTEGRITY_ATTACHMENT_FILES_TABS.PROJECT}
              className={classes.tab}
            />
          </Tabs>
          <Divider className={classes.divider} />
        </Grid>
        {activeTab === INTEGRITY_ATTACHMENT_FILES_TABS.WELL && (
          <Grid item xs={12}>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {newWellboreFiles.map((item) => {
                const labelId = `checkbox-list-label-${item.externalId}`;

                return (
                  <ListItem
                    key={item.externalId}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <CommentIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.isChecked}
                        disabled={isSubmitting}
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleWellboreFilesCheckboxToggle(item.externalId)
                        }
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={item.name}
                      secondary={
                        item.modified
                          ? moment(item.modified).format(
                              ATTACHMENTS_DATE_FORMAT,
                            )
                          : 'N/A'
                      } // not needed for the table
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
        {activeTab === INTEGRITY_ATTACHMENT_FILES_TABS.PROJECT && (
          <Grid item xs={12}>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {newProjectFiles.entrySeq().map(([projectId, files]) => (
                <React.Fragment key={`project-${projectId}`}>
                  {/* Parent Item */}
                  <ListItem button onClick={() => handleToggle(projectId)}>
                    <ListItemText primary={`Project ID: ${projectId}`} />
                    {expand[projectId] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  {/* Collapsible Nested List of fajlz */}
                  <Collapse in={expand[projectId]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {(files.toJS ? files.toJS() : files).map((file) => {
                        const labelId = `checkbox-list-label-${projectId}-${file.externalId}`;

                        return (
                          <ListItem
                            key={`item-${projectId}-${file.externalId}`}
                            secondaryAction={
                              <IconButton edge="end" aria-label="comments">
                                <CommentIcon />
                              </IconButton>
                            }
                            sx={{ pl: 4 }} // Indentation for nested items
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={file.isChecked}
                                disabled={isSubmitting}
                                onChange={() =>
                                  handleProjectFilesCheckboxToggle(
                                    projectId,
                                    file.externalId,
                                  )
                                }
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={file.name}
                              secondary={
                                file.modified
                                  ? moment(file.modified).format(
                                      ATTACHMENTS_DATE_FORMAT,
                                    )
                                  : 'N/A'
                              } // not needed for the table
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item xs={3}>
              <Button
                title="Cancel"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                onClick={toggleDrawer}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                title="Save attachments"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={submitUpdatedAttachments}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  dropzone: {
    border: '2px dashed grey',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#3e4246',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  dragActive: {
    borderColor: theme.palette.primary.main,
  },
  saveIcon: {
    marginLeft: 'auto',
  },
});

export default withStyles(styles)(UploadIntegrityAttachmentDrawerContent);
