import React from 'react';

import BooleanRuleDataFormComponent from 'features/wells/components/BooleanRuleDataFormComponent';
import GeographyRuleDataFormComponent from 'features/wells/components/GeographyRuleDataFormComponent';
import NumericRuleDataFormComponent from 'features/wells/components/NumericRuleDataFormComponent';
import SelectPicklistOptionComponent from 'features/wells/components/SelectPicklistOptionComponent';
import TimeRuleDataFormComponent from 'features/wells/components/TimeRuleDataFormComponent';

import { Formik } from 'formik';

import {
  IntegrityForm as RuleFormFields,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

import * as Yup from 'yup';
import { useState } from 'react';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { addNewIntegrityManagementWellData } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';

const WellTableValueComponent = ({ wellboreId, integrity }) => {
  const dispatch = useDispatch();

  const [selectedRuleType] = useState(integrity?.integrityDetailType);

  const FormSchema = Yup.object().shape({
    value: Yup.string().required('Required').nullable(),
  });

  const updateValue = (values) => {
    if (values.value)
      dispatch(addNewIntegrityManagementWellData(wellboreId, values, () => {}));
  };

  const initialFormValues = {
    [RuleFormFields.TITLE]: integrity.title,
    [RuleFormFields.INTEGRITY_ID]: integrity.integrityId,
    [RuleFormFields.RULE_TYPE]: integrity.integrityDetailType,
    [RuleFormFields.DEFAULT_VALUE_CHECKED]: true,
    [RuleFormFields.VALUE]:
      integrity.integrityDetailType === RULE_TYPES_ENUM.RULE_PICKLIST
        ? integrity.value.id
        : integrity.integrityDetailType === RULE_TYPES_ENUM.RULE_BOOL
        ? isNil(integrity.value)
          ? null
          : Number(integrity.value)
        : integrity.value,
    [RuleFormFields.PICKLIST_OPTIONS]: integrity.details,
  };

  const ruleTypeComponents = React.useMemo(
    () => ({
      [RULE_TYPES_ENUM.RULE_BOOL]: {
        CustomComponent: BooleanRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_DOUBLE]: {
        CustomComponent: NumericRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_GEOGRAPHY]: {
        CustomComponent: GeographyRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_TIME]: {
        CustomComponent: TimeRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_PICKLIST]: {
        CustomComponent: SelectPicklistOptionComponent,
      },
    }),
    [],
  );

  const SelectedComponent = ruleTypeComponents[selectedRuleType] || {
    CustomComponent: () => null,
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={FormSchema}
      >
        {() => (
          <SelectedComponent.CustomComponent
            updateValue={updateValue}
            integrity={integrity}
          />
        )}
      </Formik>
    </>
  );
};

export default WellTableValueComponent;
