import isNil from 'lodash/isNil';
import { useMemo, useState, useEffect } from 'react';
import { Table, TableRowActionsCell } from 'altus-ui-components';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import DescriptionIcon from '@material-ui/icons/Description';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { IconButton as NavigationIconButton } from 'app/components/withNavigation';
import { EMPTY_LIST } from 'app/app.constants';
import { INTEGRITY_TYPE_ENUM } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { toIntegrityAttachment } from 'utils/route.util';
import { List } from 'immutable';
import { toArray } from 'lodash';
import { useCallback } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { Breadcrumbs, Button } from '@material-ui/core';
import WellTableValueComponent from './WellTableValueComponent';

const WellIntegrityTable = ({
  integrities,
  wellboreId,
  fieldId,
  externalWellboreId,
  classes,
  title,
}) => {
  const [pageHistory, setPageHistory] = useState([]);
  const [rows, setRows] = useState(List());
  const [breadCrumps, setBreadCrumps] = useState([
    title
      .toLowerCase()
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
      )
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join(' '),
  ]);

  const nextPage = useCallback((row) => {
    let position = row.original.position;
    let title = row.original.title;
    setPageHistory((prevPageHistory) => [...prevPageHistory, position]);
    setBreadCrumps((prevBreadCrumpsHistory) => [
      ...prevBreadCrumpsHistory,
      title,
    ]);
  }, []);

  const goTo = (index) => {
    setBreadCrumps((prevBreadCrumpsHistory) =>
      prevBreadCrumpsHistory.slice(0, index + 1),
    );
    setPageHistory((prevPageHistory) => prevPageHistory.slice(0, index + 1));
  };

  const goBack = () => {
    if (pageHistory.length > 1) {
      setPageHistory((prevPageHistory) => prevPageHistory.slice(0, -1));
      setBreadCrumps((prevBreadCrumpsHistory) =>
        prevBreadCrumpsHistory.slice(0, -1),
      );
    }
  };

  const getTableRows = (integritiesList, index, currentIntegrity) => {
    if (pageHistory.length > index) {
      currentIntegrity = integritiesList[pageHistory[index]];
      index = index + 1;
      integritiesList = currentIntegrity?.categories;
    }
    if (pageHistory.length === index) {
      let integritiesListCategories = currentIntegrity?.categories.map(
        (category, i) => {
          return { ...category, position: i };
        },
      );
      return { ...currentIntegrity, categories: integritiesListCategories };
    }

    return getTableRows(integritiesList, index, currentIntegrity);
  };

  useEffect(
    () => {
      if (pageHistory.length === 0) {
        setPageHistory([...pageHistory, 0]);
      }
      if (integrities.size > 0 && pageHistory.length > 0) {
        let rows = getTableRows(toArray(integrities), 0, {});
        setRows(List([...(rows.categories || []), ...(rows.details || [])]));
      }
    },
    // eslint-disable-next-line
    [integrities, pageHistory],
  );
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${1}rem`,
                    },
                  }),
                })}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        Header: 'Name',
        accessor: (integrity) => integrity.title,
      },
      {
        xs: 3,
        Header: 'Value',
        accessor: (integrity) => integrity.integrityType,
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          if (
            integrity.integrityType &&
            (integrity.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE ||
              integrity.integrityType === INTEGRITY_TYPE_ENUM.CATEGORY)
          ) {
            return null;
          }

          return (
            <Grid item>
              <WellTableValueComponent
                wellboreId={externalWellboreId}
                integrity={integrity}
              />
            </Grid>
          );
        },
      },

      {
        xs: 2,
        id: 'documents',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          if (
            integrity.integrityType &&
            (integrity.integrityType === INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE ||
              integrity.integrityType === INTEGRITY_TYPE_ENUM.CATEGORY)
          ) {
            return null;
          }

          return (
            <Grid item>
              <NavigationIconButton
                size="small"
                to={toIntegrityAttachment(
                  fieldId,
                  wellboreId,
                  integrity.integrityId,
                )}
                disabled={
                  isNil(integrity.noOfAttachments) || isNil(integrity.value)
                }
              >
                <DescriptionIcon />
              </NavigationIconButton>
              ({integrity.noOfAttachments ?? 0})
            </Grid>
          );
        },
      },
      {
        xs: 1,
        id: 'show',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          if (
            integrity.integrityType &&
            integrity.integrityType === INTEGRITY_TYPE_ENUM.CATEGORY
          ) {
            return (
              <Grid item>
                <IconButton color="default" onClick={() => nextPage(row)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            );
          }

          return null;
        },
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      <Grid container className={classes.backButtonGrid} spacing={2}>
        <Grid item>
          <Button
            // className={classes.backButton}
            startIcon={<ArrowBack />}
            onClick={goBack}
            disabled={pageHistory.length <= 1} // Disable if there's no page to go back to
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          {/* Breadcrumbs */}
          {breadCrumps.length > 1 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbs}
            >
              {breadCrumps.map((crumb, index) => (
                <Typography
                  key={index}
                  color="textPrimary"
                  className={classes.breadcrumbItem}
                  onClick={() => goTo(index)}
                >
                  {crumb}
                </Typography>
              ))}
            </Breadcrumbs>
          )}
        </Grid>
      </Grid>

      <Table
        subrowOffset={3}
        items={rows ?? EMPTY_LIST}
        columns={columns}
        useGlobalFilter={true}
        noItemsMessage="There are no rules for this organization."
      />
    </>
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
  backButtonGrid: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbItem: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export default withStyles(styles)(WellIntegrityTable);
