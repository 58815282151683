import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import React from 'react';
import { IntegrityForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const NumericRuleDataFormComponent = ({ updateValue, integrity }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (integrity.value !== values.value) updateValue(values);
    // eslint-disable-next-line
  }, [values]);
  return (
    <Grid item xs={6} container>
      <Field
        type="number"
        placeholder="Default Numeric Value"
        component={TextFieldFormik}
        name={IntegrityForm.VALUE}
      />
    </Grid>
  );
};

export default NumericRuleDataFormComponent;
