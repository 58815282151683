import { Map, List } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { INTEGRITY_MANAGEMENT_ACTIONS } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

const initialState = Map({
  rules: EMPTY_LIST,
  integrityAttachments: EMPTY_LIST,
  availableFiles: EMPTY_LIST,
  riskScoreTrend: EMPTY_MAP,
});

const integrityManagementReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES:
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL:
    case INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_INTEGRITY: {
      if (action.error) return state;

      return state.set(
        'integrities',
        List(
          action.payload?.map((integrities) =>
            integrityManagementMappers.integrities.from(integrities),
          ),
        ),
      );
    }
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_WELL_INTEGRITY_ATTACHMENTS: {
      if (action.error) return state;

      return state.set(
        'integrityAttachments',
        List(
          action.payload?.map((attachments) =>
            integrityManagementMappers.attachments.from(attachments),
          ),
        ),
      );
    }
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_AVAILABLE_FILES: {
      if (action.error) return state;

      return state.set(
        'availableFiles',
        List(
          action.payload?.map((availableFiles) =>
            integrityManagementMappers.rawData.from(availableFiles),
          ),
        ),
      );
    }
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_INTEGRITY_MANAGEMENT_RISK_SCORE_TREND_FOR_WELL: {
      if (action.error) return state;

      const { externalWellboreId, scores } = action.payload;
      const key = `riskScoreTrend,${externalWellboreId}`;

      console.log('externalWellboreId', externalWellboreId);
      console.log('scores', scores);

      return state.set(
        key,
        List(
          scores?.map((riskTrendScores) =>
            integrityManagementMappers.rawData.from(riskTrendScores),
          ),
        ),
      );
    }

    default:
      return state;
  }
};

export default integrityManagementReducer;
