import moment from 'moment';
import { compose } from 'redux';
import Highcharts from 'highcharts';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { useEffect, useState, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';

import { getIntegrityManagementWellRiskScoresTrendState } from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import { loadIntegrityManagementRiskTrendScoresForWell } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';

const WellIntegrityRiskTrendChart = ({
  riskTrendScores,
  dispatchLoadIntegrityRiskScoreTrendForWell,
  externalWellboreId,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (externalWellboreId) {
      dispatchLoadIntegrityRiskScoreTrendForWell(externalWellboreId);
    }
  }, [dispatchLoadIntegrityRiskScoreTrendForWell, externalWellboreId]);

  useEffect(() => {
    if (riskTrendScores && riskTrendScores.size > 0) {
      var newRiskTrendScores = riskTrendScores
        .toJS()
        .map(({ totalScoreDate, totalScoreCalculation }) => [
          moment(totalScoreDate).valueOf(), // Convert to timestamp
          totalScoreCalculation, // TODO maybe round the value
        ])
        .sort((a, b) => a[0] - b[0]); // order by datetime just in case

      setChartData(newRiskTrendScores);
    }
  }, [riskTrendScores]);

  const gridColor = colors.grey[800];
  const labelColor = colors.grey[300];
  const purpleColor = colors.purple[400];

  const chartComponent = useRef(null);

  const chartOptions = {
    chart: {
      type: 'line',
      zoomType: 'x',
      backgroundColor: 'transparent',
      plotBorderWidth: 1,
      plotBorderColor: gridColor,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      gridLineColor: gridColor,
      gridLineWidth: 1,
      labels: {
        style: { color: labelColor },
        format: '{value:%d %b %Y}',
      },
    },
    yAxis: {
      title: {
        style: { color: labelColor },
        text: 'Risk Score',
      },
      min: 0,
      // TODO check what is the MAX risk score
      gridLineColor: gridColor,
      gridLineWidth: 1,
      labels: {
        style: { color: labelColor },
      },
    },
    series: [
      {
        name: 'Risk Score',
        data: chartData,
        color: purpleColor,
      },
    ],
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {externalWellboreId && chartData.length > 0 && (
        <HighchartsReact
          allowChartUpdate
          ref={chartComponent}
          options={chartOptions}
          highcharts={Highcharts}
        />
      )}
      {(!externalWellboreId || chartData.length === 0) && (
        <Typography variant="h6" color="textSecondary">
          No risk trend data available.
        </Typography>
      )}
    </Grid>
  );
};

export default compose(
  connect(
    (state, { externalWellboreId }) => ({
      riskTrendScores: getIntegrityManagementWellRiskScoresTrendState(
        state,
        externalWellboreId,
      ),
    }),
    {
      dispatchLoadIntegrityRiskScoreTrendForWell:
        loadIntegrityManagementRiskTrendScoresForWell,
    },
  ),
)(WellIntegrityRiskTrendChart);
